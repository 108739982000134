import { createSlice } from '@reduxjs/toolkit'


export const routeControlControllerSlice = createSlice({
    name: 'routeControlControllerSlice',
    initialState: {
        map: null,
        isApiLoaded: false,
        centerMap: [localStorage.getItem('company') && JSON.parse(localStorage.getItem('company')).map_center_lat, localStorage.getItem('company') && JSON.parse(localStorage.getItem('company')).map_center_long],
        companyLocation: [localStorage.getItem('company') && JSON.parse(localStorage.getItem('company')).center_lat, localStorage.getItem('company') && JSON.parse(localStorage.getItem('company')).center_long],
        garbageLocation: [localStorage.getItem('company') && JSON.parse(localStorage.getItem('company')).garbage_dump_site_lat, localStorage.getItem('company') && JSON.parse(localStorage.getItem('company')).garbage_dump_site_long],
        companyName: localStorage.getItem('company') && JSON.parse(localStorage.getItem('company')).company_name,
        dutyList: null,
        selectedDuty: null,
        selectedDutyContainers: null,
        infoWindowIndex: null,
        directionResponse: null,
    },

    reducers: {
        setMap: (state, action) => {
            state.map = action.payload
        },
        setApiLoaded: (state, action) => {
            state.isApiLoaded = action.payload
        },
        setCenterMap: (state, action) => {
            state.centerMap = action.payload
        },
        setCompanyLocation: (state, action) => {
            state.companyLocation = action.payload
        },
        setGarbageLocation: (state, action) => {
            state.garbageLocation = action.payload
        },
        setInfoWindowIndex: (state, action) => {
            state.infoWindowIndex = action.payload
        },
        setDutyList: (state, action) => {
            state.dutyList = action.payload
        },
        setSelectedDuty: (state, action) => {
            state.selectedDuty = action.payload
        },
        setSelectedDutyContainers: (state, action) => {
            state.selectedDutyContainers = action.payload
        },
        setDirectionResponse: (state, action) => {
            state.directionResponse = action.payload
        },

    }
})


export const {
    setGarbageLocation,
    setMap,
    setApiLoaded,
    setCenterMap,
    setCompanyLocation,
    setInfoWindowIndex,
    setDutyList,
    setSelectedDuty,
    setSelectedDutyContainers,
    setDirectionResponse
} = routeControlControllerSlice.actions

export default routeControlControllerSlice.reducer