import { createSlice } from '@reduxjs/toolkit'


export const searchToolsControllerSlice = createSlice({
    name: 'searchToolsController',
    initialState: {
        //* Search Tool Sayfasındaki Soldaki filtreleme seçemeklerini tutar
        filteringOptionsList: [],

        filterString: '',

        filterOptions: {},

        filtersList: [],
    },

    reducers: {
        setFilteringOptionsList: (state, action) => {
            state.filteringOptionsList = action.payload
        },
        setFilterString: (state, action) => {
            state.filterString = action.payload
        },
        setFilterOptions: (state, action) => {
            state.filterOptions = action.payload
        },
        setFiltersList: (state, action) => {
            state.filtersList = action.payload
        }

    }
})


export const { setFilteringOptionsList, setFilterString, setFilterOptions, setFiltersList } = searchToolsControllerSlice.actions

export default searchToolsControllerSlice.reducer