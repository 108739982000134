import { createSlice } from '@reduxjs/toolkit'


export const ModificationStepsControllerSlice = createSlice({
    name: 'ModificationStepsController',
    initialState: {
        mainStep: 1,
        step1Value: null,
        step2Index: null,
        step2Value: null,
        step3Index: null,
        step3Value: null,
        step4Index: null,
        step4Value: null,
        isNextButtonOk: false,
        stepType: '',

        checkedListPayloadParameters: {
            'mqtt_url': {
                'is': false,
                'value': null,
            },
            'mqtt_port': {
                'is': false,
                'value': null,
            },
            'mqtt_password': {
                'is': false,
                'value': null,
            },
            'mqtt_userName': {
                'is': false,
                'value': null,
            },
            'device_link': {
                'is': false,
                'value': null,
            },
            'device_depth': {
                'is': false,
                'value': null,
            },
            'device_status': {
                'is': false,
                'value': null,
            },
            'device_version': {
                'is': false,
                'value': null,
            },
            'device_fullness': {
                'is': false,
                'value': null,
            },
            'device_interval': {
                'is': false,
                'value': null,
            },
            'device_deltaTemp': {
                'is': false,
                'value': null,
            },
            'device_deltaTime': {
                'is': false,
                'value': null,
            },
            'device_sensorWakeUp': {
                'is': false,
                'value': null,
            },
            'device_fullAlarmLimit': {
                'is': false,
                'value': null,
            },
            'device_fullAlarmExitValue': {
                'is': false,
                'value': null,
            },
            'device_toleranceValue': {
                'is': false,
                'value': null,
            },
            'device_deviceStatusCheckTime': {
                'is': false,
                'value': null,
            },
            'device_fireAlarmTimeout': {
                'is': false,
                'value': null,
            },
            'device_sensorErrorValue': {
                'is': false,
                'value': null,
            },
            'device_fireAlarmHighLimit': {
                'is': false,
                'value': null,
            },
            'result_message': {
                'is': false,
                'value': null,
            },
            'result_success': {
                'is': false,
                'value': null,
            }
        },
        fixedApiData: null,
        changePayloadParameter: false,
        changePayloadParameterIsJson: true
    },

    reducers: {
        setMainStep: (state, action) => {
            state.mainStep = action.payload
        },
        setStep1Value: (state, action) => {
            state.step1Value = action.payload
        },
        setStep2Index: (state, action) => {
            state.step2Index = action.payload
        },
        setStep2Value: (state, action) => {
            state.step2Value = action.payload
        },
        setStep3Index: (state, action) => {
            state.step3Index = action.payload
        },
        setStep3Value: (state, action) => {
            state.step3Value = action.payload
        },
        setStep4Index: (state, action) => {
            state.step4Index = action.payload
        },
        setStep4Value: (state, action) => {
            state.step4Value = action.payload
        },
        setIsNextButtonOk: (state, action) => {
            state.isNextButtonOk = action.payload
        },
        setStepType: (state, action) => {
            state.stepType = action.payload
        },
        setCheckedListPayloadParameters: (state, action) => {
            state.checkedListPayloadParameters = action.payload
        },
        setFixedApiData: (state, action) => {
            state.fixedApiData = action.payload
        },
        setChangePayloadParameter: (state, action) => {
            state.changePayloadParameter = action.payload
        },
        setChangePayloadParameterIsJson: (state, action) => {
            state.changePayloadParameterIsJson = action.payload
        }
    }
})


export const { setFixedApiData, setCheckedListPayloadParameters, setMainStep, setStep1Value, setStep2Index, setStep2Value, setStep3Index, setStep3Value, setStep4Index, setStep4Value, setIsNextButtonOk, setStepType, setChangePayloadParameter, setChangePayloadParameterIsJson } = ModificationStepsControllerSlice.actions

export default ModificationStepsControllerSlice.reducer