import { createSlice } from '@reduxjs/toolkit'

export const ContainerTypeStatus = {
    allContainerStatus: 'allContainerStatus',
    noneConnectedDeviceStatus: 'noneConnectedDeviceStatus',
    connectedDeviceStatus: 'connectedDeviceStatus',
    fireAlarmStatus: 'fireAlarmStatus',
    coverAlarmStatus: 'coverAlarmStatus',
    batteryCoverAlarmStatus: 'batteryCoverAlarmStatus',
    connectionErrorStatus: 'connectionErrorStatus',
    todayCollectedStatus: 'todayCollectedStatus',
    redContainerStatus: 'redContainerStatus',
    yellowContainerStatus: 'yellowContainerStatus',
    greenContainerStatus: 'greenContainerStatus',
    filterInputStatus: 'filterInputStatus',
}


export const containerStatusInfoControllerSlice = createSlice({
    name: 'containerStatusInfoControllerSlice',
    initialState: {
        isFilteredForFireAlarm: false,
        fireAlarmContainerData: [],
        isFilteredForCoverAlarm: false,
        coverAlarmContainerData: [],
        isFilteredForBatteryCoverAlarm: false,
        batteryCoverAlarmContainerData: [],
        isFilteredForNonContainerAssaingDevice: false,
        filteredForNonContainerAssaingDeviceData: [],
        isConnectionErrorDevice: false,
        connectionErrorDeviceData: [],
        isFilteredForTodayCollect: false,
        todayCollectContainerData: [],
        isFilteredLoading: false,
        containerTypeDataStatus: {
            loading: true,
            error: false,
            success: false,
        },
        containerTypeStatus: 'allContainerStatus',
        deneme: '',
        filterInputStatus: {
            filterForWhat: '',
            filterValue: '',
        },
        sortInputStatus: {
            sortForWhat: '',             // container_name ......
            sortType: 'default',                // asc, desc, default
        },
    },

    reducers: {
        setIsFilteredForFireAlarm: (state, action) => {
            state.isFilteredForFireAlarm = action.payload
        },
        setFireAlarmContainerData: (state, action) => {
            state.fireAlarmContainerData = action.payload
        },
        setIsFilteredForCoverAlarm: (state, action) => {
            state.isFilteredForCoverAlarm = action.payload
        },
        setCoverAlarmContainerData: (state, action) => {
            state.coverAlarmContainerData = action.payload
        },
        setIsFilteredForBatteryCoverAlarm: (state, action) => {
            state.isFilteredForBatteryCoverAlarm = action.payload
        },
        setBatteryCoverAlarmContainerData: (state, action) => {
            state.batteryCoverAlarmContainerData = action.payload
        },
        setIsFilteredForNonContainerAssaingDevice: (state, action) => {
            state.isFilteredForNonContainerAssaingDevice = action.payload
        },
        setFilteredForNonContainerAssaingDeviceData: (state, action) => {
            state.filteredForNonContainerAssaingDeviceData = action.payload
        },
        setIsFilteredForTodayCollect: (state, action) => {
            state.isFilteredForTodayCollect = action.payload
        },
        setTodayCollectContainerData: (state, action) => {
            state.todayCollectContainerData = action.payload
        },
        setIsFilteredLoading: (state, action) => {
            state.isFilteredLoading = action.payload
        },
        setContainerTypeDataStatus: (state, action) => {
            state.containerTypeDataStatus = action.payload
        },
        setContainerTypeStatus: (state, action) => {
            state.containerTypeStatus = action.payload
        },
        setIsConnectionErrorDevice: (state, action) => {
            state.isConnectionErrorDevice = action.payload
        },
        setConnectionErrorDeviceData: (state, action) => {
            state.connectionErrorDeviceData = action.payload
        },
        setFilterInputStatus: (state, action) => {
            state.filterInputStatus = action.payload
        },
        setSortInputStatus: (state, action) => {
            state.sortInputStatus = action.payload
        },
        setDeneme: (state, action) => {
            state.deneme = action.payload
        }
    }
})


export const {
    setContainerTypeStatus,
    setContainerTypeDataStatus,
    setIsFilteredForFireAlarm,
    setFireAlarmContainerData,
    setIsFilteredForCoverAlarm,
    setCoverAlarmContainerData,
    setIsFilteredForBatteryCoverAlarm,
    setBatteryCoverAlarmContainerData,
    setIsFilteredForNonContainerAssaingDevice,
    setFilteredForNonContainerAssaingDeviceData,
    setIsConnectionErrorDevice,
    setConnectionErrorDeviceData,
    setIsFilteredForTodayCollect,
    setTodayCollectContainerData,
    setIsFilteredLoading,
    setFilterInputStatus,
    setSortInputStatus,
    setDeneme,
} = containerStatusInfoControllerSlice.actions

export default containerStatusInfoControllerSlice.reducer


