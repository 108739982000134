import { createSlice } from '@reduxjs/toolkit'


export const authControllerSlice = createSlice({
    name: 'menuController',
    initialState: {
        authUser: localStorage.getItem('user_info') ? JSON.parse(localStorage.getItem('user_info')) : {},
        company: localStorage.getItem('company') ? JSON.parse(localStorage.getItem('company')) : {},
    },

    reducers: {
        setAuthUser: (state, action) => {
            state.authUser = action.payload
        },
        setCompany: (state, action) => {
            state.company = action.payload
        },
    }
})


export const { setAuthUser, setCompany } = authControllerSlice.actions

export default authControllerSlice.reducer


