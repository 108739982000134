import React from "react";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    // static getDerivedStateFromError(error) {
    //     // Hata yakalandığında state'i güncelle.
    //     return { hasError: true };
    // }

    // componentDidCatch(error, errorInfo) {
    //     // Hata bilgisini bir log servisine gönderebilirsiniz.
    //     //   logErrorToMyService(error, errorInfo);
    //     console.log("first error", error)
    //     // localStorage.clear();
    // }

    // render() {
    //     if (this.state.hasError) {
    //         // Yedek UI göster.
    //         // return <h1>Bir sorun oluştu, lütfen daha sonra tekrar deneyiniz.</h1>;
    //         return this.props.children;
    //     }

    //     return this.props.children;
    // }
}


export default ErrorBoundary;