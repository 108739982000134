import { createSlice } from '@reduxjs/toolkit'


export const globalInfoCardControllerSlice = createSlice({
    name: 'globalInfoCardController',
    initialState: {
        isClickedFireAlarm: false,
        isClickedConnectionError: false,
    },

    reducers: {
        setClickedFireAlarm: (state, action) => {
            state.isClickedFireAlarm = action.payload
        },
        setClickedConnectionError: (state, action) => {
            state.isClickedConnectionError = action.payload
        },
    }
})


export const { setClickedConnectionError, setClickedFireAlarm, } = globalInfoCardControllerSlice.actions

export default globalInfoCardControllerSlice.reducer