import { createSlice } from '@reduxjs/toolkit'


export const containersRealTimeStatusControllerSlice = createSlice({
    name: 'containersRealTimeStatusController',
    initialState: {
        redContainer: null,
        yellowContainer: null,
        greenContainer: null,
        fireAlarmContainer: null,
        openCoverAlarmContainer: null,
        openBatteryCoverAlarmContainer: null,
        deviceNoneAssaignContainer: null,
        connectionErrorContainer: null,
        deviceAssaignContainer: null,
    },

    reducers: {
        setRedContainer: (state, action) => {
            state.redContainer = action.payload
        },
        setYellowContainer: (state, action) => {
            state.yellowContainer = action.payload
        },
        setGreenContainer: (state, action) => {
            state.greenContainer = action.payload
        },
        setFireAlarmContainer: (state, action) => {
            state.fireAlarmContainer = action.payload
        },
        setOpenCoverAlarmContainer: (state, action) => {
            state.openCoverAlarmContainer = action.payload
        },
        setOpenBatteryCoverAlarmContainer: (state, action) => {
            state.openBatteryCoverAlarmContainer = action.payload
        },
        setDeviceNoneAssignContainer: (state, action) => {
            state.deviceNoneAssaignContainer = action.payload
        },
        setDeviceAssaignContainer: (state, action) => {
            state.deviceAssaignContainer = action.payload
        },
        setConnectionErrorContainer: (state, action) => {
            state.connectionErrorContainer = action.payload
        },

    }
})


export const { setConnectionErrorContainer, setDeviceAssaignContainer, setRedContainer, setYellowContainer, setGreenContainer, setFireAlarmContainer, setOpenCoverAlarmContainer, setOpenBatteryCoverAlarmContainer, setDeviceNoneAssignContainer } = containersRealTimeStatusControllerSlice.actions

export default containersRealTimeStatusControllerSlice.reducer


