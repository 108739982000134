import { createSlice } from '@reduxjs/toolkit'


export const themeControllerSlice = createSlice({
    name: 'themeController',
    initialState: {
        themeIsDark: false,
    },

    reducers: {
        setThemeIsDark: (state, action) => {
            state.themeIsDark = action.payload
        },
    }
})


export const { setThemeIsDark } = themeControllerSlice.actions

export default themeControllerSlice.reducer


