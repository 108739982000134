import { createSlice } from '@reduxjs/toolkit'


export const reportControllerSlice = createSlice({
    name: 'reportControllerSlice',
    initialState: {

        staticTotalCountApiData: null,
        totalCountApiData: null,
        staticGraphApiData: null,
        graphApiData: null,
        selectedCardsForShowHideList: [
            'Toplam Konteyner Sayısı',
            'Toplam Araç Sayısı',
            'Toplam Tablet Sayısı',
            'Toplam Kullanıcı Sayısı',
            'Toplam Personel Sayısı',
            'Toplam Şoför Sayısı',
            'Toplam Bölge Sayısı',
            'Toplam Rota Sayısı',
            'Toplam Kullanıcı Tipi Sayısı',
            'Toplam Vardiya Sayısı',
            'Toplam Cihaz Sayısı',
            'Toplam Konteyner Türü Sayısı',
            'Toplam Yangın Alarmı Sayısı',
            'Toplam Şoförlerin Topladım Dediği Konteyner Sayısı',
            'Toplam Şoförlerin Harcadığı Rota Zamanı ( Saat )',
            'Toplam Şoförlerin Gittim Dediği KM ( KM )',
        ],
        staticSelectedCardsForShowHideList: [
            'Toplam Konteyner Sayısı',
            'Toplam Araç Sayısı',
            'Toplam Tablet Sayısı',
            'Toplam Kullanıcı Sayısı',
            'Toplam Personel Sayısı',
            'Toplam Şoför Sayısı',
            'Toplam Bölge Sayısı',
            'Toplam Rota Sayısı',
            'Toplam Kullanıcı Tipi Sayısı',
            'Toplam Vardiya Sayısı',
            'Toplam Cihaz Sayısı',
            'Toplam Konteyner Türü Sayısı',
            'Toplam Yangın Alarmı Sayısı',
            'Toplam Şoförlerin Topladım Dediği Konteyner Sayısı',
            'Toplam Şoförlerin Harcadığı Rota Zamanı ( Saat )',
            'Toplam Şoförlerin Gittim Dediği KM ( KM )',
        ],
        regionTabSelectedCardsForShowHideList: [
            'Harita Ekranı',
        ],
        regionTabStaticSelectedCardsForShowHideList: [
            'Harita Ekranı',
        ],
        selectedDateType: 'month',
        isStaticTotalCountApiDataLoading: true,
        isTotalCountApiDataLoading: true,
        isStaticGraphApiDataLoading: true,
        isGraphApiDataLoading: true,
        isRegionGraphApiDataLoading: true,
        regionGraphApiData: null,
        isDriverGraphApiDataLoading: true,
        driverGraphApiData: null,
        isVehicleGraphApiDataLoading: true,
        vehicleGraphApiData: null,

    },

    reducers: {
        setSelectedCardsForShowHideList: (state, action) => {
            state.selectedCardsForShowHideList = action.payload
        },
        setSelectedDateType: (state, action) => {
            state.selectedDateType = action.payload
        },
        setIsStaticTotalCountApiDataLoading: (state, action) => {
            state.isStaticTotalCountApiDataLoading = action.payload
        },
        setIsTotalCountApiDataLoading: (state, action) => {
            state.isTotalCountApiDataLoading = action.payload
        },
        setIsGraphApiDataLoading: (state, action) => {
            state.isGraphApiDataLoading = action.payload
        },
        setIsStaticGraphApiDataLoading: (state, action) => {
            state.isStaticGraphApiDataLoading = action.payload
        },
        setTotalCountApiData: (state, action) => {
            state.totalCountApiData = action.payload
        },
        setStaticTotalCountApiData: (state, action) => {
            state.staticTotalCountApiData = action.payload
        },
        setGraphApiData: (state, action) => {
            state.graphApiData = action.payload
        },
        setStaticGraphApiData: (state, action) => {
            state.staticGraphApiData = action.payload
        },
        setRegionGraphApiData: (state, action) => {
            state.regionGraphApiData = action.payload
        },
        setIsRegionGraphApiDataLoading: (state, action) => {
            state.isRegionGraphApiDataLoading = action.payload
        },
        setIsDriverGraphApiDataLoading: (state, action) => {
            state.isDriverGraphApiDataLoading = action.payload
        },
        setDriverGraphApiData: (state, action) => {
            state.driverGraphApiData = action.payload
        },
        setIsVehicleGraphApiDataLoading: (state, action) => {
            state.isVehicleGraphApiDataLoading = action.payload
        },
        setVehicleGraphApiData: (state, action) => {
            state.vehicleGraphApiData = action.payload
        },

    }
})


export const {
    setSelectedCardsForShowHideList,
    setSelectedDateType,
    setIsTotalCountApiDataLoading,
    setIsStaticTotalCountApiDataLoading,
    setIsGraphApiDataLoading,
    setIsStaticGraphApiDataLoading,
    setTotalCountApiData,
    setStaticTotalCountApiData,
    setGraphApiData,
    setStaticGraphApiData,
    setRegionGraphApiData,
    setIsRegionGraphApiDataLoading,
    setIsDriverGraphApiDataLoading,
    setDriverGraphApiData,
    setIsVehicleGraphApiDataLoading,
    setVehicleGraphApiData,

} = reportControllerSlice.actions

export default reportControllerSlice.reducer


