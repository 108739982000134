import { createSlice } from '@reduxjs/toolkit'


export const mapControllerSlice = createSlice({
    name: 'mapControllerSlice',
    initialState: {
        selectedContainer: null,
        fullnessValue: null,
        chargeValue: null,
        mapCenter: null,
        selectedContainerList: null,
    },

    reducers: {
        setSelectedContainer: (state, action) => {
            state.selectedContainer = action.payload
        },
        setFullnessValue: (state, action) => {
            state.fullnessValue = action.payload
        },
        setChargeValue: (state, action) => {
            state.chargeValue = action.payload
        },
        setMapCenter: (state, action) => {
            state.mapCenter = action.payload
        },
        setSelectedContainerList: (state, action) => {
            state.selectedContainerList = action.payload
        },
    }
})


export const { setSelectedContainerList, setSelectedContainer, setFullnessValue, setChargeValue, setMapCenter } = mapControllerSlice.actions

export default mapControllerSlice.reducer