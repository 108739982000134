import { createSlice } from '@reduxjs/toolkit'


export const routeManagementControllerSlice = createSlice({
    name: 'routeManagementController',
    initialState: {
        filterDutyList: [],
        filterParams: []
    },

    reducers: {
        setFilterDutyList: (state, action) => {
            state.filterDutyList = action.payload
        },
        setFilterParams: (state, action) => {
            state.filterParams = action.payload
        },
    }
})


export const { setFilterDutyList, setFilterParams } = routeManagementControllerSlice.actions

export default routeManagementControllerSlice.reducer


