export const routes = {
    asset_url: {
        path: `${process.env.REACT_APP_ASSET_URL}`,
        title: "Ana Sayfa",
        title_eng: "Home Page"
    },
    simple_url: {
        path: `${process.env.REACT_APP_UNSPLASH_URL}`,
        title: "Ana Sayfa",
        title_eng: "Home Page"
    },
    notFound: {
        path: "*",
        title: "Not Found",
        title_eng: "Not Found"
    },
    login: {
        path: "/login",
        title: "Giriş Sayfası",
        title_eng: "Login Page"
    },
    home_page: {
        path: "/",
        title: "Ana Sayfa",
        title_eng: "Home Page"
    },
    map_route_managemenet: {
        path: "/route-managemenet",
        title: "Harita Rota Yönetimi",
        title_eng: "Map Route Management",
        check_route: {
            path: "/check-route",
            title: "Rota Denetleme",
            title_eng: "Route Checking"
        },
    },
    maps: {
        path: "/maps",
        title: "Harita Ekranı",
        title_eng: "Container Map"
    },
    chauffeur_monitoring: {
        path: "/chauffeur-monitoring",
        title: "Şoför İzleme Ekranı",
        title_eng: "Chauffeur Monitoring Screen"
    },
    container: {
        path: "/container",
        title: "Konteyner",
        title_eng: "Container"
    },
    deneme: {
        path: "/deneme",
        title: "Konteyner Ekranı 2",
        title_eng: "Container Screen 2"
    },
    planning: {
        path: "/planning",
        title: "Planlama Ekranı",
        title_eng: "Planning Screen",
        add: {
            path: "/planning/new-plan",
            title: "Yeni Plan Ekle",
            title_eng: "Add New Plan"
        },
        sort: {
            path: "/planning/sort/:id",
            title: "Planı Sırala",
            title_eng: "Sort Plan"
        },
        updata: {
            path: "/planning/:id",
            title: "Planı Güncelle",
            title_eng: "Update Plan"
        }
    },
    notification: {
        path: "/notification",
        title: "Bildirim Ekranı",
        title_eng: "Notification Screen"
    },
    user_managemenet: {
        path: "/user-managemenet",
        title: "Kişi Ayarları",
        title_eng: "User Settings"
    },
    settings: {
        path: "/settings",
        title: "Ayarlar",
        title_eng: "Settings"
    },
    tablet_driver: {
        path: "/tablet-driver",
        title: "Şoför İşlemleri",
        title_eng: "Driver Operations"
    },
    setup: {
        path: "/setup",
        title: "Kurulum",
        title_eng: "Setup"
    },
    report: {
        path: "/report",
        title: "Rapor ve İstatistik",
        title_eng: "Reports and Statistics"
    },
    react_table: {
        path: "/react-table",
        title: "React Table",
        title_eng: "React Table"
    },
    device_management: {
        path: "/device-management",
        title: "Cihaz Yönetimi",
        title_eng: "Device Management"
    },
    device_settings: {
        path: "/device-settings",
        title: "Cihaz Ayarları",
        title_eng: "Device Settings"
    },
    modifications: {
        path: "/modifications",
        title: "Cihaz Modifikasyonu",
        title_eng: "Device Modification"
    },
    system_management: {
        path: "/system-management",
        title: "Sistem Yönetimi",
        title_eng: "System Management"
    },
    device_qr_operation: {
        path: "/device-qr-operation",
        title: "Cihaz Qr İşlemi",
        title_eng: "Device QR Operation"
    },
    device_box_qr_operation: {
        path: "/device-box-qr-operation",
        title: "Cihaz Kutulama İşlemi",
        title_eng: "Device Boxing Operation"
    },
    device_box_shipment: {
        path: "/device-box-shipment",
        title: "Sevkiyat Oluştur",
        title_eng: "Create Shipment"
    },
    shipping_customer_managemenet: {
        path: "/shipping-customer-managemenet",
        title: "Müşteri İşlemleri",
        title_eng: "Customer Operations"
    },
    shipping_managemenet: {
        path: "/shipping-managemenet",
        title: "Sevkiyat Yönetimi",
        title_eng: "Shipment Management"
    },
    device_test: {
        path: "/device-test",
        title: "Cihaz Test",
        title_eng: "Device Test"
    },
    constances_settings: {
        path: "/constances",
        title: "Default Ayarlar",
        title_eng: "Default Settings"
    },
    permissions: {
        path: "/permissions",
        title: "İzin Kontrol",
        title_eng: "Permission Control"
    },
    database: {
        path: "/database",
        title: "Database",
        title_eng: "Database"
    },
    search_tool: {
        path: "/search-tool",
        title: "Search Tool",
        title_eng: "Search Tool"
    },
    device_assembly_operation: {
        path: "/device-assembly-operation",
        title: "Cihaz Montaj Operasyonu",
        title_eng: "Device Assembly Operation"
    },
    password_reset: {
        path: "/reset-password",
        title: "Şifre Sıfırlama",
        title_eng: "Password Reset"
    },
    app_version: {
        path: "/app-version",
        title: "Mobil Uygulama Versiyonu",
        title_eng: "App Version"
    }
};
