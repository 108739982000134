import { createSlice } from '@reduxjs/toolkit';

export const homePageControllerSlice = createSlice({
    name: 'homePageController',
    initialState: {
        settingsMenuIsOpen: false,
        settingsCards: new Map([
            [
                'LOGO_IMG',
                {
                    id: 1,
                    code: 'LOGO_IMG',
                    title: 'Logo Kartı',
                    isSelected: true,
                    description: 'Kurum Logosunun Olduğu Kart',
                }
            ],
            [
                'COMPANY_INFO',
                {
                    id: 2,
                    code: 'COMPANY_INFO',
                    title: 'Kurum Bilgileri',
                    isSelected: false,
                    description: 'Kurum Bilgilerinin Olduğu Kart',
                }
            ],
            [
                'CONTAINER_COLOR_INFO',
                {
                    id: 3,
                    code: 'CONTAINER_COLOR_INFO',
                    title: 'Konteyner Renk Bilgileri',
                    isSelected: false,
                    description: 'Konteyner Renk Bilgilerinin Olduğu Kart',
                }
            ],
            [
                'RED_CONTAINER_COLOR_INFO',
                {
                    id: 4,
                    code: 'RED_CONTAINER_COLOR_INFO',
                    title: 'Kırmızı Konteyner Renk Bilgileri',
                    isSelected: false,
                    description: 'Kırmızı Konteyner Renk Bilgilerinin Olduğu Kart',
                }
            ],
            [
                'YELLOW_CONTAINER_COLOR_INFO',
                {
                    id: 5,
                    code: 'YELLOW_CONTAINER_COLOR_INFO',
                    title: 'Sarı Konteyner Renk Bilgileri',
                    isSelected: false,
                    description: 'Sarı Konteyner Renk Bilgilerinin Olduğu Kart',
                }
            ],
            [
                'GREEN_CONTAINER_COLOR_INFO',
                {
                    id: 6,
                    code: 'GREEN_CONTAINER_COLOR_INFO',
                    title: 'Yeşil Konteyner Renk Bilgileri',
                    isSelected: false,
                    description: 'Yeşil Konteyner Renk Bilgilerinin Olduğu Kart',
                }
            ],
            [
                'CONTAINER_TYPE_INFO',
                {
                    id: 7,
                    code: 'CONTAINER_TYPE_INFO',
                    title: 'Konteyner Tipine Göre Konteyner Sayısı',
                    isSelected: false,
                    description: 'Konteyner Tip Bilgilerinin Olduğu Kart',
                }
            ],
            [
                'DEVICE_INFO',
                {
                    id: 8,
                    code: 'DEVICE_INFO',
                    title: 'Cihaz Bilgileri',
                    isSelected: false,
                    description: 'Cihaz Bilgilerinin Olduğu Kart',
                }
            ],
            [
                'CONTAINERS_FROM_REGIONS_INFO',
                {
                    id: 9,
                    code: 'CONTAINERS_FROM_REGIONS_INFO',
                    title: 'Bölgelere Göre Konteyner Sayısı Bilgileri',
                    isSelected: false,
                    description: 'Bölge Bilgilerinin Olduğu Kart',
                }
            ],
            [
                'FIRE_ALARM_INFO',
                {
                    id: 11,
                    code: 'FIRE_ALARM_INFO',
                    title: 'Yangın Alarm Bilgileri',
                    isSelected: false,
                    description: 'Yangın Alarm Bilgilerinin Olduğu Kart',
                }
            ],
            [
                'COVER_ALARM_INFO',
                {
                    id: 12,
                    code: 'COVER_ALARM_INFO',
                    title: 'Cihaz Kapak Alarm Bilgileri',
                    isSelected: false,
                    description: 'Cihaz Kapak Alarm Bilgilerinin Olduğu Kart',
                }
            ],
            [
                'BATTERY_ALARM_INFO',
                {
                    id: 13,
                    code: 'BATTERY_ALARM_INFO',
                    title: 'Cihaz Pil Kapak Alarm Bilgileri',
                    isSelected: false,
                    description: 'Cihaz Pil Kapak Alarm Bilgilerinin Olduğu Kart',
                }
            ],
        ])
    },

    reducers: {
        setSettingsMenuIsOpen: (state, action) => {
            state.settingsMenuIsOpen = action.payload;
        },
        toggleCardSelection: (state, action) => {
            const cardId = action.payload;
            const card = state.settingsCards.get(cardId);
            if (card) {
                state.settingsCards.set(cardId, { ...card, isSelected: !card.isSelected });
            }
        },
        setSettingsCards: (state, action) => {
            state.settingsCards = action.payload;
            // const cardsArray = action.payload;
            // state.settingsCards = new Map(cardsArray.map((card) => [card.id, card]));
        }
    }
});

export const { setSettingsMenuIsOpen, toggleCardSelection, setSettingsCards } = homePageControllerSlice.actions;

export default homePageControllerSlice.reducer;
